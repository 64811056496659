import './App.css';
import React, { Component } from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import web3 from "web3";
import logo from './assets/logo.jpeg';
import detectEthereumProvider from '@metamask/detect-provider';
import example from './assets/example.gif';
import mM from './assets/MetaMask.png';
import MainPic from './assets/bg.png';
import openseaImg from './assets/opensea2.png';
import CoinbaseWalletSDK, { CoinbaseWalletProvider } from "@coinbase/wallet-sdk";
import WalletConnect from "@walletconnect/web3-provider";
import { ethers } from 'ethers';
import Web3 from "web3";
import Web3Modal from "web3modal";
import { useRef, useEffect, useState } from "react";
import { providers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import nftCreator from './assets/pic.jpg';

const bnb = () => {
	window.open("https://donationsbnb.treepoets.com/");
}

const poly = () => {
	window.open("https://donations.treepoets.com/");
}

const org = () => {
	window.open("https://treepoetry.org/");
}


var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;


let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let maxMintNFTs;
let onlyLeft;
let now;
let myTotalWL;
let contract;
let wMintAmount = 1;
let totalWhitelistMinted;
let walletBalance;
let wStatus;
let publicSale;
let myNFTBalance;
let gasLimit = 285000;
let whitelistStatus;
let owner;


const so1 = () => {
	window.open("https://testnets.opensea.io/collection/testingbeta-v3");
}

const oS = () => {
	window.open("https://opensea.io/collection/tree-poets");
}

const nftCreatorWeb = () => {
	window.open("https://treepoetry.org/");
}

const ABI =

	[
		{
			"inputs": [
				{
					"internalType": "string",
					"name": "_initBaseURI",
					"type": "string"
				}
			],
			"stateMutability": "nonpayable",
			"type": "constructor"
		},
		{
			"inputs": [],
			"name": "ApprovalCallerNotOwnerNorApproved",
			"type": "error"
		},
		{
			"inputs": [],
			"name": "ApprovalQueryForNonexistentToken",
			"type": "error"
		},
		{
			"inputs": [],
			"name": "ApprovalToCurrentOwner",
			"type": "error"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "to",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "approve",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "ApproveToCaller",
			"type": "error"
		},
		{
			"inputs": [],
			"name": "BalanceQueryForZeroAddress",
			"type": "error"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "quantity",
					"type": "uint256"
				}
			],
			"name": "mint",
			"outputs": [],
			"stateMutability": "payable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "MintToZeroAddress",
			"type": "error"
		},
		{
			"inputs": [],
			"name": "MintZeroQuantity",
			"type": "error"
		},
		{
			"inputs": [],
			"name": "OwnerIndexOutOfBounds",
			"type": "error"
		},
		{
			"inputs": [],
			"name": "OwnerQueryForNonexistentToken",
			"type": "error"
		},
		{
			"inputs": [
				{
					"internalType": "bool",
					"name": "_state",
					"type": "bool"
				}
			],
			"name": "pause",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "renounceOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "from",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "to",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "safeTransferFrom",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "from",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "to",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				},
				{
					"internalType": "bytes",
					"name": "_data",
					"type": "bytes"
				}
			],
			"name": "safeTransferFrom",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "operator",
					"type": "address"
				},
				{
					"internalType": "bool",
					"name": "approved",
					"type": "bool"
				}
			],
			"name": "setApprovalForAll",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "string",
					"name": "_newBaseURI",
					"type": "string"
				}
			],
			"name": "setBaseURI",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_cost",
					"type": "uint256"
				}
			],
			"name": "setMintRate",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "TokenIndexOutOfBounds",
			"type": "error"
		},
		{
			"inputs": [],
			"name": "TransferCallerNotOwnerNorApproved",
			"type": "error"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "from",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "to",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "transferFrom",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "TransferFromIncorrectOwner",
			"type": "error"
		},
		{
			"inputs": [],
			"name": "TransferToNonERC721ReceiverImplementer",
			"type": "error"
		},
		{
			"inputs": [],
			"name": "TransferToZeroAddress",
			"type": "error"
		},
		{
			"inputs": [],
			"name": "URIQueryForNonexistentToken",
			"type": "error"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "owner",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "approved",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "Approval",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "owner",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "operator",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "bool",
					"name": "approved",
					"type": "bool"
				}
			],
			"name": "ApprovalForAll",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "previousOwner",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "OwnershipTransferred",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "from",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "to",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "Transfer",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "transferOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "withdraw",
			"outputs": [],
			"stateMutability": "payable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "owner",
					"type": "address"
				}
			],
			"name": "balanceOf",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "cost",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "getApproved",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "owner",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "operator",
					"type": "address"
				}
			],
			"name": "isApprovedForAll",
			"outputs": [
				{
					"internalType": "bool",
					"name": "",
					"type": "bool"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "MAX_SUPPLY",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "name",
			"outputs": [
				{
					"internalType": "string",
					"name": "",
					"type": "string"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "owner",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "ownerOf",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "paused",
			"outputs": [
				{
					"internalType": "bool",
					"name": "",
					"type": "bool"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "bytes4",
					"name": "interfaceId",
					"type": "bytes4"
				}
			],
			"name": "supportsInterface",
			"outputs": [
				{
					"internalType": "bool",
					"name": "",
					"type": "bool"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "symbol",
			"outputs": [
				{
					"internalType": "string",
					"name": "",
					"type": "string"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "index",
					"type": "uint256"
				}
			],
			"name": "tokenByIndex",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "owner",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "index",
					"type": "uint256"
				}
			],
			"name": "tokenOfOwnerByIndex",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "tokenId",
					"type": "uint256"
				}
			],
			"name": "tokenURI",
			"outputs": [
				{
					"internalType": "string",
					"name": "",
					"type": "string"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "totalSupply",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		}
];

const address = "0xD53EEAF08dAebA65e99e5E925D2381410FF11276";

class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "",
		presaleValue: "",
		maxmint: '',
		stateNow: '',
		totalWhitelistSupply: '',
		myTotalWLNFTs: '',
		accountBalance: '',
		wlStatus: '',
		wlEvent: false,
		myNFTWallet: '',
		priceDisplay: ''

	}

	onSubmitMinus = async event => {
		event.preventDefault();
		console.log("MintAmount :" + mintAmount);
		mintAmount = mintAmount - 1;
		if (mintAmount < 1) {
			mintAmount = 1
		}
		this.setState({ nftMintingAmount: mintAmount });


		if (owner == account) {
			console.log("Owner's Wallet: " + owner);

			this.setState({ nftMintingAmount: mintAmount });


		} else {

			if (totalSupplyNFT < 8784) {

				onlyLeft = 8784 - totalSupplyNFT;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * 80000000000000000;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}

		}
	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;


		if (owner == account) {
			console.log("owner : " + owner);

			onlyLeft = 8784 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;

			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });




		} else {

			if (totalSupplyNFT < 8784) {

				onlyLeft = 8784 - totalSupplyNFT;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * 80000000000000000;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			} else {

				if (totalSupplyNFT == 8784) {

					onlyLeft = 8784 - totalSupplyNFT;
					mintAmount = onlyLeft;
					this.setState({ msg: "SOLD OUT" });

				}
			}


		}
	}
/*
	walletbtn = async event => {
		event.preventDefault();

		const provider = await detectEthereumProvider();

		if (provider) {

			console.log('Ethereum successfully detected!');



			// From now on, this should always be true:
			// provider === window.ethereum

			// Access the decentralized web!

			//.....................................................................//

			(async () => {

				const chainId = 1;

				if (window.ethereum.networkVersion !== chainId) {
					try {
						await window.ethereum.request({
							method: 'wallet_switchEthereumChain',
							params: [{ chainId: web3.utils.toHex(chainId) }],
						});
					} catch (err) {
						// This error code indicates that the chain has not been added to MetaMask.
						if (err.code === 4902) {
							await window.ethereum.request({
								method: 'wallet_addEthereumChain',
								params: [
									{
										chainName: 'Ethereum Mainnet',
										chainId: web3.utils.toHex(chainId),
										nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
										rpcUrls: ['https://etherscan.io'],
									},
								],
							});
						}
					}
				}




				if (window.ethereum) {
					await window.ethereum.send('eth_requestAccounts');
					window.web3 = new web3(window.ethereum);
					//.....................................................................//
					//.....................................................................//


					contract = new window.web3.eth.Contract(ABI, address);


					let accounts = await window.web3.eth.getAccounts();
					account = accounts[0];
					this.setState({ walletAddress: account });

					console.log("Wallet Address" + this.state.walletAddress);

					try {
						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });

						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);

						if (owner == account) {
							console.log("owner : " + owner);
							
							onlyLeft = 8784 - totalSupplyNFT;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}

							valueOfNFTs = mintAmount * 0;

							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						} else {

								if (totalSupplyNFT == 8784) {

									onlyLeft = 8784 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {

									onlyLeft = 8784 - totalSupplyNFT;
									mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * 80000000000000000;

									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}

						
					} catch (err) {
						console.log("err: " + err);

					}

				}


			})();

			//.....................................................................//






			// Legacy providers may only have ethereum.sendAsync
			const chainId = await provider.request({
				method: 'eth_chainId'
			})

		} else {

			// if the provider is not detected, detectEthereumProvider resolves to null
			console.error('Please install MetaMask!');
			alert('A valid provider could not be found!');

		}


	}
*/
	publicMint = async event => {
		event.preventDefault();


		console.log(this.state.walletAddress);

		try {

			owner = await contract.methods.owner().call();
			let totalGasLimit = String(gasLimit * mintAmount);



			if (account != owner) {


				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					//contract = new window.web3.eth.Contract(ABI,'0x802ec13e2e3afe078bc15035f80f0f82bea9bc6c');

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: String(totalGasLimit), from: account, value: this.state.totalValue * 1 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					//contract = new window.web3.eth.Contract(ABI,'0x802ec13e2e3afe078bc15035f80f0f82bea9bc6c');

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: String(totalGasLimit), from: account, value: this.state.totalValue * 1 * 0 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}
			}
		} catch (err) {

			console.log(err);

		}
	}


	walletConnect = async event => {
		event.preventDefault();


		const providerOptions = {
			coinbasewallet: {
				package: CoinbaseWalletSDK, // Required
				options: {
					appName: "My Awesome App", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 1, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});









		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			if (provider) {


				(async () => {

					const chainId = 1;

					if(provider != "coinbasewallet"){

					

					if (window.ethereum.networkVersion !== chainId) {
						try {
							await window.ethereum.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await window.ethereum.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											chainName: 'Ethereum Mainnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
											rpcUrls: ['https://etherscan.io'],
										},
									],
								});
							}
						}
					}




					try {
						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });

						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);

						if (owner == account) {
							console.log("owner : " + owner);

							mintAmount = 1;

							onlyLeft = 8784 - totalSupplyNFT;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}

							valueOfNFTs = mintAmount * 0;

							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						} else {

								if (totalSupplyNFT == 8784) {

									onlyLeft = 8784 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = 8784 - totalSupplyNFT;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * 80000000000000000;

									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}

						
					} catch (err) {
						console.log("err: " + err);

					}
				}else{



					if (CoinbaseWalletProvider.networkVersion !== chainId) {
						try {
							await CoinbaseWalletProvider.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await CoinbaseWalletProvider.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											chainName: 'Ethereum Mainnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
											rpcUrls: ['https://etherscan.io'],
										},
									],
								});
							}
						}
					}




					try {
						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });

						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);

						if (owner == account) {
							console.log("owner : " + owner)
							onlyLeft = 8784 - totalSupplyNFT;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}

							valueOfNFTs = mintAmount * 0;

							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						} else {

								if (totalSupplyNFT == 8784) {

									onlyLeft = 8784 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {

									onlyLeft = 8784 - publicSale;
									mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * 80000000000000000;

									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}

						
					} catch (err) {
						console.log("err: " + err);

					}



				}
				})();

				//.....................................................................//






				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}






		}

	}



	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			coinbasewallet: {
				package: CoinbaseWalletSDK, // Required
				options: {
					appName: "TreePoet", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 1, // Optional. It defaults to 1 if not provided
					darkMode: false // Optional. Use dark theme, defaults to false
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();



	}


	render() {

		return (

			<div>
				<div class="allWrap">
					<div class="navBar">
						<div class="socialContainerTop">
							<div class="socialContainer">
								<div class="discord">
									<SocialIcon url="https://linktr.ee/TreePoetry" target="_blank" label="Our portfolio" bgColor="#ffff" style={{ height: 40, width: 40 }} />
								</div>

								<div class="discord">
									<img class="osImg" target="_blank" onClick={oS} src={openseaImg} />
								</div>

								<div>

								</div>

							</div>
						</div>

					</div>
					<div class="title">< img class="titlepic" src={logo} onClick={nftCreatorWeb} /></div>
					<div class="section3mainDiv">

						<div class="section1">
							<img onClick={poly} class="gif" src={example} />
						</div>

						<div class="section2">
							<div class="sec2Title">{this.state.totalSupply} / 8784</div>
							<div class="link"><a href="https://etherscan.io/address/0xD53EEAF08dAebA65e99e5E925D2381410FF11276">0xD53EEAF08d...</a></div>

							<div class="price">1 TP costs 0.08 ETH</div>



							<div class="nftblockWalletConnectedALL">



								{this.state.walletAddress === '' ? (

									<div class="walletConnect">
										<form onSubmit={this.walletConnect}>
											<button class="tab1" type='submit'>CONNECT</button>
										</form>

									</div>

									
								) :

									(<div class="mintDiv"><div class="minting_count_button">

										<div>
											<form onSubmit={this.onSubmitMinus}>
												<button class="btnfos-0-2" type="submit">-</button>
											</form>
										</div>

										<div>
											<div class="nftminter2"><span class="nftamount">{this.state.nftMintingAmount}</span></div>
										</div>


										<div>
											<form onSubmit={this.onSubmitPlus}>
												<button class="btnfos-0-2" type="submit">+</button>
											</form>
										</div>


									</div>

										<div class="mintbuttondiv">
											<form onSubmit={this.publicMint}>

												<button class="mintButton" type="submit">
													Mint for {this.state.totalValue / 1000000000000000000} ETH</button>
											</form>
										</div>
										<div>

											{this.state.statusError ? (
												<div class="errorMessage">
													<div >Sorry, something went wrong please try again later</div>
												</div>)
												: null}

											{this.state.statusLoading ? (
												<div class="loadingContainer">
													<div>
														<div class="loadingText"> Waiting For Confirmation</div>
														<div class="loadTextSub">(this can take up to 30 seconds)</div>
													</div>
												</div>)
												: null}

											{this.state.success ? (

												<div class="successfully">WOW... MINTING SUCCESSFUL!</div>)
												: null}

										</div></div>)}
							</div>

						</div>

						<div class="section3">
							<button class="tag1" onClick={bnb}><img class="oS" src={example} /></button>

						</div>
					</div>

					<div class="note">Tree Poets NFTs provide proof of intellectual property ownership when creating collectibles and assigning copy right ownership to owner of the collectible and allow transfer to be granted from creator to future owner. If collectible is gifted to fulfill successful donation request then social token rewards are sent to wallet of Tree Poet when collectible was created. The social tokens have no monetary value but are used to obtain the free collectible gifts that are unsuccessful in collecting charitable donations. Collectible gifts can be created from any featured poetry and image of creator or from public domain so there are no transferred copyright as selected within dapp when creating collectible.</div>

					<div class="note2">Collectible gifts created to transfer ownership require same image to be uploaded at TreePoetry.org/collectiblegifts form with contact info and signature so transfer can be a legal contract. If proof of ownership on token or social tokens are not desired then image can be uploaded at TreePoetry.org/collectiblegifts to create a non tokenized collectible gift for your donation request. All collectible transfers and social token rewards are issued 30 days after donation requests are successful so credit card chargebacks can not be made to reclaim donations since charities do not give refunds and chargebacks cannot be made after 30 days. To create a tokenized collectible gift, click the Tree Poets images above, connect your wallet and select your Tree Poet in your wallet.</div>
				</div >

			</div >)
	}
}

export default App;
